import { useAuth0 } from '@auth0/auth0-react'
import { FC, MouseEvent, useEffect, useState } from 'react'
import { BackButton, Xxllnc } from '../components'
import { LOADING_TEXT, LOGIN_TEXT, NOT_CONFIGURED_TEXT, NO_ORGANIZATION_TEXT, PROVIDE_EMAIL } from '../constants/constants'
import '../styles.css'
import { AuthOrganization } from '../types/ecoTypes'
import { getEnvVariable, optimisticFetch } from '../utils'

const getApiUrl = (): string => getEnvVariable('REACT_APP_CONTROLPANEL_URL', '')

const OrganizationPage: FC = () => {
  const { loginWithRedirect } = useAuth0()
  const [showEmail, setShowEmail] = useState(false)
  const [email, setEmail] = useState<string>()
  const [ready, setReady] = useState(false)
  const [organization, setOrganization] = useState<AuthOrganization[]>()

  const search = new URLSearchParams(location?.search)
  const emailParam = search?.get('email') as string
  const organizationParam = search?.get('organization') as string
  const code = search?.get('code') as string
  const callbackParam = search?.get('callback') as string

  useEffect(() => {
    // If we have a callbackUrl, we need to store it in localstorage so we can use it later
    if (callbackParam) localStorage.setItem('callbackUrl', callbackParam)
  }, [callbackParam])

  useEffect(() => {
    if (emailParam && callbackParam) {
      if (!organizationParam) {
        // If we have a email, but no organization, we need to get the organization from login_info
        void getOrganization(emailParam)
      } else {
        // If we have email and organization, we can go directly to login
        login({
          auth0Identifier: organizationParam,
          organizationName: '',
          organizationId: '',
        })
      }
    } else {
      // If we dont have email and callback, we need to show the email input field
      setReady(true)
      setShowEmail(true)
    }
  }, [emailParam, callbackParam, organizationParam])

  useEffect(() => {
    // Go directly to login if there is only one organization returned from login_info
    if (organization?.length === 1) login(organization[0])
  }, [organization])

  const getOrganization = async (emailAddress: string) => {
    const url = `${getApiUrl()}/login_info?filter={"emailAddress":"${emailAddress}"}`
    const result = await optimisticFetch<AuthOrganization[]>({ url })
    setOrganization(Array.isArray(result) ? result : [])
    setReady(true)
  }

  const onChangeInput = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEmail(event.target.value as string)
  }

  const handleCheckEmail = (event: MouseEvent<HTMLButtonElement>): void => {
    if (email === undefined) return
    if (organizationParam) {
      // If we have a organization, we can go directly to login
      login({
        auth0Identifier: organizationParam,
        organizationName: '',
        organizationId: '',
      })
      return
    }
    // If we dont have a organization, we need to get the organization from login_info
    event.preventDefault()
    void getOrganization(email)
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>, org: AuthOrganization): void => {
    event.preventDefault()
    login(org)
  }

  const login = (org: AuthOrganization): void => {
    // store the selected organization in localstorage so we can add it to the callback url
    localStorage.setItem('organization', org.auth0Identifier)

    // eslint-disable-next-line camelcase
    void loginWithRedirect({ authorizationParams: { organization: org.auth0Identifier, login_hint: emailParam || email }})
  }

  const getInstructionsText = (): string => {
    if (code) return ''
    if (!callbackParam) return NOT_CONFIGURED_TEXT
    if (organization && organization.length > 1) return LOGIN_TEXT
    if (!emailParam) return PROVIDE_EMAIL
    if (!organization) return LOADING_TEXT
    if (organization.length === 0) return NO_ORGANIZATION_TEXT
    return LOGIN_TEXT
  }

  // Dont show anything if were not ready or if we're bussy with login
  if (!ready || organization?.length === 1) return <div className='page' />

  return (
    <div className='page'>
      <Xxllnc />
      <div className='container'>
        <p>{ getInstructionsText() }</p>
        { !organization && showEmail && <>
          <input onChange={onChangeInput} placeholder="E-mail" />
          <button className='orgButton' disabled={email === undefined} onClick={handleCheckEmail} >Verder</button>
        </>}
        { organization && organization.length > 1 && organization.map(org => (
          <button className='orgButton' key={org.auth0Identifier} onClick={(event) => handleClick(event, org)} >
            { org.organizationName }
          </button>
        ))}
      </div>
      <BackButton />
    </div>
  )
}

export default OrganizationPage
