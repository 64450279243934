declare global {
  interface Window { env: { [key: string]: string } }
}

export const getEnvVariable = (name: string, backupValue = ''): string => {
  try {
    return window?.env?.[String(name)] ?? process.env?.[String(name)] ?? backupValue
  } catch (e) {
    return backupValue
  }
}