import { Auth0Provider } from '@auth0/auth0-react'
import { FC, useEffect } from 'react'
import { BackButton, Xxllnc } from './components'
import { NOT_CONFIGURED_TEXT } from './constants/constants'
import Organization from './pages/Organization'
import './styles.css'
import { getEnvVariable } from './utils'

const getDomain = (): string => getEnvVariable('REACT_APP_AUTH0_DOMAIN', '')

const App: FC = () => {
  const search = new URLSearchParams(location?.search)
  const code = search?.get('code') as string
  const audience = search?.get('a') as string
  const client = search?.get('c') as string
  const callback = search?.get('callback') as string
  const organization = search?.get('organization') as string

  useEffect(() => {
    if (code) {
      // If there is a code, the login was successful and we can redirect to the callback url
      const organizationId = localStorage.getItem('organization')
      const callbackUrl = localStorage.getItem('callbackUrl')

      if (typeof window !== 'undefined' && organizationId && callbackUrl) {
        // Organization and callbackurl were stored in localstorage before we redirected to auth0
        window.location.href = `${callbackUrl}?organization=${organizationId}`
      }
    }
  }, [code])


  // If there is a code, we will be redirected shortly
  if (code) return <div className='page'/>

  if (!audience || !client) {
    // If there is no audience or client we should show an error message
    return (
      <div className='page'>
        <Xxllnc />
        <div className='container'>
          <p>{ NOT_CONFIGURED_TEXT }</p>
        </div>
        <BackButton />
      </div>
    )
  }

  return (
    <Auth0Provider
      cacheLocation='localstorage'
      clientId={client}
      domain={getDomain()}
      useRefreshTokens={true}
      onRedirectCallback={() => {
        console.log('Redirect ')
      }}
      authorizationParams={{
        audience,
        redirectUri: organization ? callback : window.location.origin
      }}
    >
      <Organization />
    </Auth0Provider>
  )
}

export default App
