const NOT_FOUND_MESSAGE = 'Er is een fout opgetreden, het formulier is niet gevonden. ' +
  'Neem contact op met de organisatie waarbij u het formulier probeert in te dienen of probeer het later opnieuw.'

const UNKNOWN_ERROR_MESSAGE = 'Er heeft zich een onbekende fout voorgedaan. ' +
  'Neem contact op als het probleem blijft bestaan.'


interface Detail {
  loc: { detail: Detail[] }
  msg?: string
  type?: string
}
interface MakeStringOfError {
  detail: Detail[]
  message?: string
}
const makeStringOfError = (err: MakeStringOfError) => {

  let returnString = ''
  returnString += err?.message ?? ''

  const details = err?.detail
  if (Array.isArray(details)) {

    details.forEach(detail => {
      if (detail?.loc) {
        returnString += makeStringOfError(detail.loc)

      } else {
        returnString += detail?.msg ? ` => ${detail.msg}` : ''
      }
    })
  }
  return returnString
}

export const parseResponse = async <T>(response: Response): Promise<T> => {
  try {
    return await response.json() as T
  } catch (_) {
    return {} as unknown as T
  }
}

export const checkResponse = async <T>(response: Response): Promise<T> => {
  if (response.status === 404)
    throw new Error(NOT_FOUND_MESSAGE)
  if (response.status >= 500 )
    throw new Error(UNKNOWN_ERROR_MESSAGE)
  if (!response.ok) {
    const parsedError = await response.json() as MakeStringOfError
    const message = parsedError?.message ? makeStringOfError(parsedError) : UNKNOWN_ERROR_MESSAGE
    throw new Error(message)
  }
  try {
    return await response.json() as T

  } catch (_) {
    throw new Error(UNKNOWN_ERROR_MESSAGE)
  }
}
