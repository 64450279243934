import { parseResponse } from '.'

interface Props {
  method?: 'GET' | 'POST'
  url: string
  headers?: Headers
}

export const normalFetch = <T>({ method = 'GET', url, headers = new Headers() }: Props): Promise<T> =>
  fetch(url, { method })
    .then(response => parseResponse<T>(response))

export const optimisticFetch = <T>({ method = 'GET', url, headers }: Props): Promise<T> =>
  normalFetch<T>({ method, url, headers })
    .catch(_ => ({}) as unknown as T)