import { FC } from 'react'

export const BackButton: FC = () => {
  const handleClick = () => {
    window.history.back()
  }

  return (
    <button className='backButton' onClick={handleClick}>
      Terug naar de applicatie
    </button>
  )
}
